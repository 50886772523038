import Loading from "@components/Loading";
import { UnauthenticatedApp } from "@components/UnauthenticatedApp";
import { useAuth } from "oidc-react";
import { PropsWithChildren, useEffect, useState } from "react";

import { OpenAPI as CuratorOpenAPI } from "@web/curator";
import { OpenAPI as IamOpenAPI } from "@web/iam";
import { OpenAPI as SopimusOpenAPI } from "@web/sopimus";

type AuthState = "loading" | "authenticated" | "notAuthenticated";

const AuthCheckpoint = ({ children }: PropsWithChildren) => {
  const [state, setState] = useState<AuthState>("loading");
  const auth = useAuth();

  useEffect(() => {
    if (auth && auth.userData) {
      const handleToken = async () => {
        const token = auth.userData?.access_token;

        CuratorOpenAPI.TOKEN = token;
        IamOpenAPI.TOKEN = token;
        SopimusOpenAPI.TOKEN = auth.userData?.access_token;

        setState("authenticated");
      };
      handleToken().catch(console.error);
    } else {
      if (auth.isLoading) {
        setState("loading");
      } else {
        setState("notAuthenticated");
      }
    }
  }, [setState, auth.userData, auth.isLoading, auth]);

  if (state === "loading") {
    return <Loading />;
  }

  if (state === "notAuthenticated") {
    return <UnauthenticatedApp />;
  }

  return <>{children}</>;
};

export default AuthCheckpoint;
