import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { MenuItem } from "./MenuItem";

export function MenuInner() {
  return (
    <>
      <MenuInnerWithSub to="/iam" title="IAM" menuPlacement="bottom-start" menuTrigger="click">
        <MenuItem to="/iam/me" title="Me" hasBullet={true} />
        <MenuItem to="/iam/accounts" title="Accounts" hasBullet={true} />
        <MenuItem to="/iam/clients" title="Clients" hasBullet={true} />
        <MenuItem to="/iam/roles" title="Roles" hasBullet={true} />
        <MenuItem to="/iam/policies" title="Policies" hasBullet={true} />
      </MenuInnerWithSub>

      <MenuInnerWithSub
        to="/sopimus"
        title="Sopimus"
        menuPlacement="bottom-start"
        menuTrigger="click"
      >
        <MenuItem to="/sopimus/contracts" title="Contracts" hasBullet={true} />
      </MenuInnerWithSub>

      <MenuInnerWithSub
        to="/curator"
        title="Curator"
        menuPlacement="bottom-start"
        menuTrigger="click"
      >
        <MenuItem to="/curator/catalogs" title="Catalogs" hasBullet={true} />
      </MenuInnerWithSub>
    </>
  );
}
