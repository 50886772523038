import { SidebarMenuItem } from "./SidebarMenuItem";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";

const SidebarMenuMain = () => {
  return (
    <>
      <SidebarMenuItemWithSub to="/iam" title="IAM" icon="element-plus">
        <SidebarMenuItem to="/iam/me" title="Me" hasBullet={true} />
        <SidebarMenuItem to="/iam/accounts" title="Accounts" hasBullet={true} />
        <SidebarMenuItem to="/iam/clients" title="Clients" hasBullet={true} />
        <SidebarMenuItem to="/iam/roles" title="Roles" hasBullet={true} />
        <SidebarMenuItem to="/iam/policies" title="Policies" hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to="/sopimus" title="Sopimus" icon="element-plus">
        <SidebarMenuItem to="/sopimus/contracts" title="Contracts" hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItemWithSub to="/curator" title="Curator" icon="element-plus">
        <SidebarMenuItem to="/curator/catalogs" title="Catalogs" hasBullet={true} />
      </SidebarMenuItemWithSub>
    </>
  );
};

export { SidebarMenuMain };
